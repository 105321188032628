<template>
  <div>
    <el-card>
      <el-form :model="form" ref="form" :rules="rule" label-width="110px" :inline="false" size="normal">
        <el-form-item label="姓名" prop="realName">
          <el-col :span="8">
            <el-input v-model="form.realName" :maxlength="16" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="form.type" @change="">
            <el-radio :label="1">
              提供服务
            </el-radio>
            <el-radio :label="2">
              需要资源
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合作内容" prop="content">
          <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="form.content">
          </el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="领域" prop="field">
          <el-checkbox-group v-model="form.field" :max="3">
            <el-checkbox :label="item.key+''" v-for="(item, index) in configDictionaries.cooperationField" :key="index">{{ item.value }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="地区" prop="area">
          <el-checkbox-group v-model="form.area" :max="3">
            <el-checkbox :label="item.key+''" v-for="(item, index) in 
                configDictionaries.cooperationServerArea" :key="index">{{ item.value }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="合作截止时间" prop="endTime">
          <el-radio-group v-model="form.endTimeType" @change="">
          <el-radio  :label="0" >长期有效</el-radio>
          <el-radio  :label="1">   <div class="box">
            <el-date-picker :disabled="form.endTimeType == 0" v-model="form.endTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%" align="right" />
          </div></el-radio>

          </el-radio-group>
          

        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请输入" v-model="form.isHide" size="medium">
            <el-option label="上架" :value="0"></el-option>
            <el-option label="下架" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片">
          <uplist
            :action="action"
            :list="this.form.productPicture"
            @changes="c_img"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '提交'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '../../components/upImg/index.vue'
import rules from '../../utils/rules'
import uplist from '../../components/uplist/index'
export default {
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      id: null,
      form: {
        realName: '',
        type: '',
        field: [],
        content: '',
        area: [],
        isHide:'',
        productPicture:[]
      },
      header: {
        Token: sessionStorage.getItem('token')
      },
      rule: {
        name: [rules.req('请输入企业名称')],
        logo: [rules.req('请输入企业logo')],
        website: [rules.req('请输入公司官网')],
        city: [rules.req('请输入实际公司地址')],
        people: [rules.req('请输入公司人事')],
        financing: [rules.req('请输入融资情况')],
        type: [rules.req('请输入类型')],
        business: [rules.req('请输入行业')],
        serverArea: [rules.req('请输入服务区域')],
        companyDesc: [rules.req('请输入公司简介')]
      },
      action: {
        action: 'cooperation'
      },
      province: {},
      city: {},
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      configDictionaries:{}
    }
  },
  created() {
    this.getp()
    this.getform()
        this.$http
      .get('/admin/Common/getConfigDictionaries?config=cooperation')
      .then(({ data: res }) => {
        this.configDictionaries = res.data
      })
  },
  methods: {
    c_img(val) {
      this.form.productPicture = val
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        console.log(res)
        this.form.productPicture.push({
          name: file.name,
          url: this.baseurl + this.yu + res.data.url
        })
      } else {
        this.form.productPicture = [...this.form.productPicture]
        this.$message.error(res.message)
      }
      // this.form.banner = URL.createObjectURL(file.raw)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      // var arr = this.form.productPicture.filter(item => item.url != file.url)
      this.form.productPicture = fileList
    },
    async getp() {
      let res = await this.$store.dispatch('getProvince')
      this.province = res.data
    },
    async getc() {
      this.form.city = ''
      let { data: res } = await this.$store.dispatch(
        'getCity',
        this.form.province
      )
      this.city = res
    },
    op() {
      this.getc()
    },
    async onSubmit() {
      var url = this.form.productPicture.map(item =>
        item.url
      )
      url = url.toString()
      if (this.id) {
        var { data: res } = await this.$http.post('/admin/Cooperation/edit', {
          ...this.form,
          field: this.form.field.toString(),
          area: this.form.area.toString(),
          productPicture: url,
          id: this.id
        })
      } else {
        var { data: res } = await this.$http.post('/admin/Cooperation/add', {
          ...this.form,
          field: this.form.field.toString(),
          area: this.form.area.toString(),
          productPicture: url
        })
      }

      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$router.push('/cooperation_list')
      } else {
        this.$message.error(res.message)
      }
    },
    async getform() {
      // console.log(this.$route.query + 'sadsadsad')
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/Cooperation/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              var yyds = res.data.productPicture.split(',').filter(Boolean)
              // console.log(yyds.filter(true));
              var url = []
               yyds.forEach(item => {
                //  if(item){
                url.push({
                  name: '1',
                  url: item
                })
                //  }

              })
              this.form = {
                ...res.data,
                field: res.data.field.split(',').filter(Boolean),
                area: res.data.area.split(',').filter(Boolean),
                productPicture:url
              }
              // console.log(this.form)
              this.form.id = id * 1
              //  this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    setImg(val) {
      this.form.logo = val
    }
  },
  computed: {},
  components: {
    imgup,
    uplist
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  width: 300px;
}
</style>
